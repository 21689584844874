<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
    extends: Doughnut,
    props: { chartData: Object, aggregateValue: String, chartIndex: String },
    mixins: [reactiveProp],
    data: function() {
        return {
            options: {
                responsive: true,
                cutoutPercentage: 80,
                maintainAspectRatio: true,
                animation: {
                    animateRotate: true
                },
                legend: {
                    display: false
                },
                tooltips: {
                    callbacks: {
                        title: function(tooltipItem, data) {
                            return data.labels[tooltipItem[0].index]
                        },
                        label: function(tooltipItem, data) {
                            return data.datasets[0].data[tooltipItem.index].toLocaleString()
                        }
                    }
                }
            }
        }
    },
    methods: {
        buidChart(newText) {
            this.addPlugin({
                id: `doughnut_${this.$props.chartIndex}_text`,
                beforeDraw: function(chart) {
                    chart.chart.clear()
                    var width = chart.chart.width
                    var height = chart.chart.height
                    var ctx = chart.chart.ctx
                    ctx.restore()
                    var fontSize = (height / 114).toFixed(2)
                    ctx.font = fontSize + 'em  Whitney SSm A, Whitney SSm B, Helvetica Neue, Helvetica, Arial, sans-serif'
                    var textX = Math.round((width - ctx.measureText(newText).width) / 2)
                    var textY = height / 2
                    ctx.textBaseline = 'middle'
                    ctx.fillText(newText, textX, textY)
                    ctx.save()
                }
            })
            this.renderChart(this.chartData, this.options)
        }
    },
    mounted() {
        this.buidChart(this.$props.aggregateValue)
    },
    watch: {
        aggregateValue: function(nextVal, preVal) {
            if (nextVal !== preVal) {
                this.buidChart(nextVal)
            }
        }
    }
}
</script>
